.grouped-preferences {
  &-building {
    &-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-icons {
      display: flex;
      gap: 5rem;
      margin-bottom: 3rem;
      margin-right: 3rem;
      margin-top: 3rem;

      @include respond(tab-port) {
        display: none;
      }
    }

    &-icon {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &-main {
    &-container {
      margin: 0 auto;
      max-width: 118rem;
      padding-bottom: 20rem;
      padding-top: 3rem;

      @include respond(tab-port) {
        margin: 0 1.5rem;
      }
    }

    &-link {
      align-items: center;
      display: flex;
      gap: 1rem;

      @include respond(tab-port) {
        flex-direction: column;
        margin-top: 2.5rem;
        text-align: center;
      }
    }

    &-header {
      align-items: center;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 10px 30px 0 rgba(172, 176, 187, 0.1);
      display: flex;
      gap: 2rem;
      margin: 0 0 2rem;
      padding: 3rem;

      @include respond(tab-port) {
        display: none;
      }

      &-svg {
        height: 5rem;
        opacity: 0.5;
        width: 5rem;
      }
    }
  }

  &-container {
    align-items: flex-start;
    align-self: stretch;
    background: $white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
    padding: 3rem;
  }

  &-description-container {
    display: flex;
    gap: 5rem;
    justify-content: space-between;

    @include respond(tab-port) {
      display: block;
    }
  }

  &-checkbox {
    appearance: none;
    background-color: $white;
    border: 2px solid $light-gray-200;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    height: 2.4rem;
    position: relative;
    width: 2.4rem;

    &:checked {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $white;

      &::after {
        content: "\2713";
        font-size: 1.5rem;
        left: 4px;
        position: absolute;
        top: -3px;
      }
    }

    &-label {
      display: none;
      @include respond(tab-port) {
        color: $tertiary-color;
        display: block;
      }
    }

    &-container {
      display: flex;
      gap: 5rem;

      @include respond(tab-port) {
        flex-direction: column;
        gap: 2rem;
        margin-top: 1rem;
      }

      &-inner {
        @include respond(tab-port) {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &-alert-container {
    width: 100%;
  }
}
