.modal {
  &-container {
    box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
    margin: auto 0;
    width: 50rem;

    @include respond(phone) {
      height: 100%;
      margin: 0;
      width: 100%;
    }
  }

  &-input {
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      @include respond(phone) {
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
    }
    border: none;
    border-bottom: 1px solid rgb(221, 221.8, 223);
    margin-bottom: 3rem;
    width: 30rem;

    @include respond(phone) {
      width: 100%;
    }
  }

  &-title {
    &-container {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  &-create {
    &-container {
      box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
      margin: auto 0;
      width: 100rem;

      @include respond(phone) {
        height: 100%;
        margin: 0;
        width: 100%;
      }
    }

    &-body {
      background: $background-gray;
      border-radius: 0 0 10px 10px;
      padding: 3rem;

      &-inner {
        background-color: $white;
        border-radius: 5px;
        padding: 3rem 4rem;
      }

      @include respond(phone) {
        height: 100%;
        margin: 0;
        width: 100%;
      }
    }
  }

  &-wizard-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 0 3rem 3rem;

    &-form {
      &-container {
        align-self: baseline;
        background-color: $white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin: 3rem 0;
        padding: 3rem 4rem;
        width: 100%;
      }

      &-input {
        border: none;
        border-bottom: 1px solid $light-gray-400;
        width: 30rem;

        @include respond(phone) {
          width: 100%;
        }
      }

      &-buttons {
        @include respond(phone) {
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
        }

        &-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          @include respond(phone) {
            flex-direction: column-reverse;
          }
        }

        &-learn-more {
          @include respond(phone) {
            align-self: center;
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  &-header {
    align-items: center;
    background-color: $white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 3.4rem;

    &-img {
      cursor: pointer;
    }
  }

  &-body {
    align-items: center;
    background-color: $background-gray;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 4rem;

    @include respond(phone) {
      height: 100%;
      justify-content: center;
      padding: 0 4rem;
    }

    &-reconfiguration-text {
      font-size: 2rem;
    }

    &-text-container {
      margin: 2.5rem 0;
      text-align: center;
    }

    %body-button {
      border: none;
      box-shadow: none;
      color: $white;
      padding: 1.6rem 2.2rem;
      text-decoration: none;
    }

    &-button {
      @extend %body-button;
      background: $tertiary-color;

      &:hover {
        background: $tertiary-color;
        box-shadow: none;
      }
    }

    &-primary-button {
      @extend %body-button;
      background: $primary-color;

      &:hover {
        background: $primary-color;
        box-shadow: none;
      }
    }

    &-learn-more-button {
      margin-top: 2rem;
    }
  }

  &-extra-number {
    display: inline;
    margin-left: -0.2rem;
  }
}

.login-result-modal {
  &-title {
    &-container {
      margin: 6rem 0 3rem;

      @include respond(phone) {
        margin: 5rem 0 3rem;
      }
    }
  }

  &-body {
    &-container {
      max-width: 43rem;
      text-align: center;

      @include respond(phone) {
        max-width: 27rem;
      }
    }
  }

  &-button {
    margin-top: 5rem;
    padding: 1.6rem;
    width: 100%;

    @include respond(phone) {
      margin-top: 3rem;
      padding: 1.2rem;
    }
  }

  &-container {
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 8rem 9rem;

    @include respond(phone) {
      padding: 7rem 3rem;
    }
  }
}

.entrata-logo {
  margin-left: 2rem;
  @include respond(phone) {
    height: 2.6rem;
    margin-left: 1.5rem;
    width: 13.6rem;
  }
}

.remotelock-logo {
  height: 3.5rem;
  margin-left: 2rem;
  width: 25rem;
  @include respond(phone) {
    height: 2.6rem;
    margin-left: 1.5rem;
    width: 15.6rem;
  }
}

.brilliant-logo {
  margin-right: 2rem;
  @include respond(phone) {
    height: 2.6rem;
    margin-right: 1.5rem;
    width: 10rem;
  }
}

.multiply-icon {
  margin-top: 0.8rem;
  @include respond(phone) {
    height: 1.4rem;
    width: 1.4rem;
  }
}

.warning-icon {
  height: 9rem;
  width: 9rem;
  @include respond(phone) {
    height: 7rem;
    width: 7rem;
  }
}
